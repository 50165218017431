import { useState } from "react";
import { FiSend } from "react-icons/fi";

export const MessageInput = ({
  onSend,
  setMessages,
  setDisableInput,
  disableInput,
  threadCreated,
}) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSend(message);
      setMessage("");
      setDisableInput(true);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="message-input">
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type Message"
        disabled={disableInput || !threadCreated}
      />
      <button type="submit" disabled={disableInput || !threadCreated}>
        <FiSend />
      </button>
    </form>
  );
};
