import { MessageInput } from "./MessageInput";
import { useEffect, useRef, useState } from "react";
import "./HomePage.css";

export const HomePage = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const [disableInput, setDisableInput] = useState(false);
  const [threadCreated, setThreadCreated] = useState(true);
  const [pendingAnswer, setPendingAnswer] = useState(false);
  const messageListRef = useRef(null);

  useEffect(() => {
    createThreadOnLoad();
  }, []);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  function splitTextAndJson(data) {
    const jsonRegex = /```json\s*([\s\S]*?)\s*```/;
    const jsonMatch = data.match(jsonRegex);

    let json = null;
    if (jsonMatch) {
      let jsonString = jsonMatch[1]
        .replace(/,\s*([\]}])/g, "$1") // Remove trailing commas
        .replace(/\n/g, "") // Remove newlines
        .replace(/\s+/g, " "); // Collapse whitespace

      try {
        json = JSON.parse(jsonString);
      } catch (e) {
        console.error("Failed to parse JSON:", e);
      }
    }

    const text = data.replace(jsonRegex, "").trim();

    return { text, json };
  }

  const createThreadOnLoad = async () => {
    try {
      const response = await fetch("https://api.openai.com/v1/threads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v2",
          Authorization: `Bearer sk-svcacct-6i7o8RhqT8XgXPnVUMVp9GZn7PWAWQvaacMpgYCxXueWVpzNGhqMvTaQrdJHuET3BlbkFJTSZJdKvavahXBe2ZSALWd086s8i9zJaqIAAvazDFPjfAQesAuM-ji5XXyh9N0A`,
        },
      });

      if (response.ok) {
        setThreadCreated(true);
        const data = await response.json();
        localStorage.setItem("thread_creation_date", data.created_at);
        localStorage.setItem("threadId", data.id);
      } else {
        setThreadCreated(false);
      }
    } catch (error) {
      setThreadCreated(false);
      console.error("Error creating a thread:", error);
    }
  };

  const onSend = async (message) => {
    const threadId = localStorage.getItem("threadId");
    try {
      const response = await fetch(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
            Authorization: `Bearer sk-svcacct-6i7o8RhqT8XgXPnVUMVp9GZn7PWAWQvaacMpgYCxXueWVpzNGhqMvTaQrdJHuET3BlbkFJTSZJdKvavahXBe2ZSALWd086s8i9zJaqIAAvazDFPjfAQesAuM-ji5XXyh9N0A`,
          },
          body: JSON.stringify({
            content: message,
            role: "user",
          }),
        },
      );
      if (response.ok) {
        setPendingAnswer(true);
        const data = await response.json();
        const messages = data.content.map((el) => {
          return {
            role: "user",
            value: el.text.value,
          };
        });
        console.log(messages);
        setMessages((prevMessages) => [
          ...prevMessages,
          ...messages,
          {
            role: "assistant",
            value: (
              <span>
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </span>
            ),
          },
        ]);
        onRun();
      }
      setDisableInput(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onRun = async () => {
    const threadId = localStorage.getItem("threadId");
    try {
      const response = await fetch(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
            Authorization: `Bearer sk-svcacct-6i7o8RhqT8XgXPnVUMVp9GZn7PWAWQvaacMpgYCxXueWVpzNGhqMvTaQrdJHuET3BlbkFJTSZJdKvavahXBe2ZSALWd086s8i9zJaqIAAvazDFPjfAQesAuM-ji5XXyh9N0A`,
          },
          body: JSON.stringify({
            assistant_id: "asst_FLC3alKD3ZXqzv42x5Qzrjup",
          }),
        },
      );
      if (response.ok) {
        setTimeout(() => onGetMessageHistory(), 2500);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getTicket = async (json) => {
    setDisableInput(true);
    const payload = {
      description: json.description,
      title: json.title,
      reporterId: localStorage.getItem("reporterId") || "Anon",
      issueType: "Bug",
    };
    try {
      const response = await fetch(
        `https://austin-app-ohnj4.ondigitalocean.app/ticket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
            Authorization: `Bearer sk-svcacct-6i7o8RhqT8XgXPnVUMVp9GZn7PWAWQvaacMpgYCxXueWVpzNGhqMvTaQrdJHuET3BlbkFJTSZJdKvavahXBe2ZSALWd086s8i9zJaqIAAvazDFPjfAQesAuM-ji5XXyh9N0A`,
          },
          body: JSON.stringify(payload),
        },
      );
      if (response.ok) {
        const data = await response.json();
        getSlackHook(payload.title, data.url);
        setMessages((prevMessages) => {
          return [
            ...prevMessages,
            {
              role: "assistant",
              value: data.message,
              link: data.url,
            },
          ];
        });
      } else {
        const data = await response.json();
        setMessages((prevMessages) => {
          return [
            ...prevMessages,
            {
              role: "assistant",
              value: data.message,
            },
          ];
        });
      }
    } catch (e) {
      console.error(e);
      setMessages((prevMessages) => {
        return [
          ...prevMessages,
          {
            role: "assistant",
            value: e.message || "An unexpected error occurred.",
          },
        ];
      });
    } finally {
      setDisableInput(false);
    }
  };

  const getSlackHook = async (title, url) => {
    const payload = {
      title: title,
      url: url,
    };
    console.log("here", payload);

    try {
      const response = await fetch(
        `https://austin-app-ohnj4.ondigitalocean.app/notify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
            Authorization: `Bearer sk-svcacct-6i7o8RhqT8XgXPnVUMVp9GZn7PWAWQvaacMpgYCxXueWVpzNGhqMvTaQrdJHuET3BlbkFJTSZJdKvavahXBe2ZSALWd086s8i9zJaqIAAvazDFPjfAQesAuM-ji5XXyh9N0A`,
          },
          body: JSON.stringify(payload),
        },
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onGetMessageHistory = async () => {
    const threadId = localStorage.getItem("threadId");
    try {
      const response = await fetch(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
            Authorization: `Bearer sk-svcacct-6i7o8RhqT8XgXPnVUMVp9GZn7PWAWQvaacMpgYCxXueWVpzNGhqMvTaQrdJHuET3BlbkFJTSZJdKvavahXBe2ZSALWd086s8i9zJaqIAAvazDFPjfAQesAuM-ji5XXyh9N0A`,
          },
        },
      );
      if (response.ok) {
        const history = await response.json();
        const lastMsg = history.data[0];
        const lastText = lastMsg.content[0]?.text.value || "";
        const { json, text } = splitTextAndJson(lastText);
        if (json) {
          getTicket(json);
        }

        setMessages((prevMessages) => {
          //if asutin returns mesages without response
          if (text === prevMessages[prevMessages.length - 2]?.value) {
            onGetMessageHistory();

            return [
              ...prevMessages.slice(0, -1),
              {
                role: "assistant",
                value: "🙂",
              },
            ];
          }
          return [
            //slicing Austin is typing msg before inserting his response
            ...prevMessages.slice(0, -1),
            {
              role: lastMsg.role,
              value: lastMsg.content.length ? text : "🙂",
            },
          ];
        });

        if (history.data[0].content.length === 0) {
          onGetMessageHistory();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="chat-container">
      {!threadCreated && (
        <div className="temporarily-unavailable">
          <p>I'm temporarily unavailable 😞</p>
        </div>
      )}
      <div className="message-list" ref={messageListRef}>
        {messages.map((msg, index) => (
          <div className={`message-wrapper wrapper-${msg.role}`}>
            {msg.role === "assistant" && (
              <img
                src="austin.jpeg"
                alt="Assistant Avatar"
                className="avatar"
              />
            )}
            <div className={`message ${msg.role}`}>
              {msg.role === "assistant" && msg.link ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${msg.value} <a href="${msg.link}" target="_blank" rel="noopener noreferrer">Jira ink</a>`,
                  }}
                />
              ) : (
                <span>{msg.value}</span>
              )}
            </div>
          </div>
        ))}
      </div>
      <MessageInput
        onSend={onSend}
        setMessages={setMessages}
        disableInput={disableInput}
        setDisableInput={setDisableInput}
        threadCreated={threadCreated}
      />
    </div>
  );
};
