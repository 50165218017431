import "./Header.css";

export const Header = ({ userId }) => {
  return (
    <header className="chat-header">
      <div className="header-content">
        <div className="text-container">
          <div className="austin">AUSTIN AI</div>
          <div className="online">
            <div className="online-dot"></div>Online
          </div>
        </div>
      </div>
    </header>
  );
};
