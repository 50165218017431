import "./App.css";

import { PrivacyPage } from "./components/privacy/PrivacyPage";
import { HomePage } from "./components/main/HomePage";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  const query = useQuery();
  const userId = query.get("id");
  if (userId) {
    localStorage.setItem("reporterId", userId);
  }
  return (
    <div className="App">
      <Header userId={userId} />
      <Routes>
        <Route path="/" element={<HomePage userId={userId} />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
