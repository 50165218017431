import "./Privacy.css";
import { Link } from "react-router-dom";
export const PrivacyPage = () => {
  return (
    <div className="privacy-wrapper">
      <div className="privacy-page">
        <Link to="/" className="back-home-link" style={{}}>
          &larr; Back Home
        </Link>
        <h1>Privacy Policy for Austin Bot</h1>
        <p>
          <strong>Effective Date:</strong> 13th Nov 2024
        </p>
        <p>
          This Privacy Policy explains how we collect, use, and share your
          information when you interact with Austin Bot. Your privacy is
          important to us, and we are committed to protecting your data.
        </p>

        <section>
          <h2>1. Information We Collect</h2>
          <p>
            When you use Austin Bot, we may collect the following types of
            information:
          </p>
          <ul>
            <li>
              <strong>User-Provided Information:</strong> Information you
              directly provide, such as messages or commands.
            </li>
            <li>
              <strong>Usage Data:</strong> Data about your interactions with
              Austin Bot, such as features used and errors encountered.
            </li>
            <li>
              <strong>Device Information:</strong> Information like IP address,
              browser type, or operating system to help troubleshoot and enhance
              compatibility.
            </li>
          </ul>
        </section>

        <section>
          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Respond to your queries and commands</li>
            <li>Improve Austin Bot’s performance and features</li>
            <li>Analyze feature effectiveness and user satisfaction</li>
            <li>Diagnose and resolve technical issues</li>
          </ul>
        </section>

        <section>
          <h2>3. Data Sharing and Disclosure</h2>
          <p>
            We do not sell your personal information. We may share data only as
            follows:
          </p>
          <ul>
            <li>
              <strong>Service Providers:</strong> Trusted third-party vendors
              assisting us in operating and improving Austin Bot.
            </li>
            <li>
              <strong>Legal Requirements:</strong> As required by law to protect
              rights, property, or safety.
            </li>
          </ul>
        </section>

        <section>
          <h2>4. Data Retention</h2>
          <p>
            We retain collected data only as long as necessary for the purposes
            outlined in this policy, or as required by law.
          </p>
        </section>

        <section>
          <h2>5. Security</h2>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, but no internet-based service is completely
            secure.
          </p>
        </section>

        <section>
          <h2>6. Children's Privacy</h2>
          <p>
            Austin Bot is not intended for use by individuals under 13. If we
            discover we have collected data from a child under 13, we will
            delete it.
          </p>
        </section>

        <section>
          <h2>7. Your Rights</h2>
          <p>Depending on your jurisdiction, you may have the right to:</p>
          <ul>
            <li>Access, update, or delete your information</li>
            <li>Restrict or object to certain processing activities</li>
            <li>Request a copy of your data in a commonly used format</li>
          </ul>
          <p>
            To exercise these rights, please contact us at{" "}
            <a href="mailto:legal@hardrockdigital.com">
              legal@hardrockdigital.com
            </a>
            .
          </p>
        </section>

        <section>
          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Any updates
            will be posted here, with an updated effective date.
          </p>
        </section>

        <section>
          <h2>9. Contact Us</h2>
          <p>
            If you have questions about this Privacy Policy or our data
            practices, please contact us at:
          </p>
          <p>
            Email:{" "}
            <a href="mailto:legal@hardrockdigital.com">
              legal@hardrockdigital.com
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};
